<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="typeNo" style="margin-right: 40px">
            <div style="display: inline-block" class="divSpan">
              <ELselect
                v-model="queryinfo.type"
                :options="types"
                :optionKey="Nameskeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 100px"
              ></ELselect>
            </div>
            <div style="display: inline-block">
              <el-input
                placeholder="请输入"
                v-model.trim="queryinfo.typeNo"
                clearable
                @clear="getList"
                style="width: 180px"
              >
              </el-input>
            </div>
          </el-form-item>

          <el-form-item prop="registTime" style="margin-right: 40px">
            <div style="display: inline-block" class="divSpan">注册时间：</div>
            <div style="display: inline-block">
              <el-date-picker
                type="datetimerange"
                v-model="queryinfo.registTime"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="截止日期"
                @change="dataSearch"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>

          <!-- <el-form-item prop="queryname" style="margin-right: 40px">
            <div style="display: inline-block" class="divSpan">vip用户：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.queryname"
                :options="Names"
                :optionKey="Nameskeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 80px"
              ></ELselect>
            </div>
          </el-form-item> -->
          <el-form-item prop="Frozentype" style="margin-right: 40px">
            <div style="display: inline-block" class="divSpan">冻结状态：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.Frozentype"
                :options="Frozens"
                :optionKey="Frozenkeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 120px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-form>
      </el-row>
      <el-row :gutter="30" style="text-align: right; margin-right: 40px">
        <el-button type="primary" @click="addUser">新增用户</el-button>
        <el-button type="primary" @click="giveVip">开通VIP</el-button>
        <el-button type="primary" @click="setPlatUser">官方账号</el-button>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="UserList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="UserID"
            label="用户ID"
            min-width="150px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            sortable
            min-width="150px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="email"
            label="邮箱"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="registerTime"
            label="注册时间"
            min-width="200px"
          ></el-table-column>
          <el-table-column label="vip用户" min-width="100px">
            <template slot-scope="scope">{{
              scope.row.isVip ? "是" : "否"
            }}</template></el-table-column
          >
          <el-table-column label="vip到期时间" min-width="150px">
            <template slot-scope="scope">
              <div
                :style="
                  scope.row.isExpire == '0'
                    ? { color: 'black' }
                    : scope.row.isExpire == '1'
                    ? { color: 'red' }
                    : { color: 'orange' }
                "
              >
                {{ scope.row.validTime }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="weixin"
            label="微信号"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="company_name"
            label="企业名称"
            min-width="100px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="post_name"
            label="职位"
            min-width="100px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="city"
            label="所在城市"
            min-width="100px"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column label="操作" width="100px" fixed="right">
            <template slot-scope="scope">
              <el-button
                :type="
                  Number(scope.row.FrozenType) === 1 ? 'warning' : 'primary'
                "
                size="mini"
                @click="freeze(scope.row)"
                >{{ Number(scope.row.FrozenType) === 1 ? "冻结" : "取消冻结" }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="新增用户"
      :visible.sync="addDialogVisible"
      width="30%"
      @close="addDialogClosed"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="用户名：" prop="username">
          <el-input v-model.trim="addForm.username" clearable></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input
            show-password
            v-model.trim="addForm.password"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmAdd">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="开通VIP"
      :visible.sync="giveDialogVisible"
      width="30%"
      @close="giveDialogClosed"
    >
      <el-form
        :model="addForm1"
        :rules="addFormRules1"
        ref="addFormRef1"
        label-width="150px"
      >
        <el-form-item label="套餐类型：" prop="packageKindId">
          <ELselect
            v-model="addForm1.packageKindId"
            :options="KindIds"
            :optionKey="KindIdskeys"
            Splaceholder="请选择"
          ></ELselect>
        </el-form-item>
        <el-form-item label="用户ID：" prop="userID">
          <el-input
            v-model.trim="addForm1.userID"
            clearable
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="VIP到期日期：" prop="VIPdate">
          <el-date-picker
            v-model="addForm1.VIPdate"
            type="datetime"
            placeholder="选择到期日期"
            style="width: 100%"
            value-format="yyyy-MM-dd HH:mm:ss"
            default-time="23:59:59"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          label="开通方式："
          prop="VIPtype"
          style="text-align: left"
        >
          <el-radio-group v-model="addForm1.VIPtype" @change="radioGroups">
            <el-radio :label="0">免费赠送</el-radio>
            <el-radio :label="1">线下购买</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-show="addForm1.VIPtype == 1">
          <el-form-item
            label="销售员："
            prop="sellerId"
            :rules="addFormRules1.price"
          >
            <ELselect
              v-model="addForm1.sellerId"
              :options="sellIds"
              :optionKey="sellIdsskeys"
              Splaceholder="请选择"
            ></ELselect>
          </el-form-item>
          <el-form-item
            label="成交价格："
            prop="price"
            :rules="addFormRules1.price"
          >
            <el-input v-model.trim="addForm1.price" clearable></el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="content">
            <el-input v-model.trim="addForm1.content" clearable></el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="giveDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmGive">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="官方账户"
      :visible.sync="setDialogVisible"
      width="40%"
    >
      <el-button type="primary" style="display: flex" @click="addPlatUser"
        >添加官方账户</el-button
      >
      <el-row
        :gutter="30"
        style="display: flex; margin-right: 1px; margin-left: 1px"
      >
        <el-table
          :data="PlatUserList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          :cell-style="{
            textAlign: 'center',
          }"
          tooltip-effect="dark"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column
            prop="id"
            label="用户ID"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="username"
            label="用户名"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="email"
            label="邮箱"
            align="center"
          ></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                :type="scope.row.username === 'admin' ? 'info' : 'warning'"
                size="mini"
                @click="delets(scope.row)"
                :disabled="scope.row.username === 'admin' ? true : false"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="添加官方账户"
      :visible.sync="addPlatDialogVisible"
      width="20%"
      @close="addPlatDialogClosed"
    >
      <el-form
        :model="addPlat"
        :rules="addPlatFormRules"
        ref="addPlatFormRef"
        label-width="100px"
      >
        <el-form-item label="用户ID：" prop="userID">
          <el-input
            v-model.trim="addPlat.userID"
            placeholder="请输入"
            type="number"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addPlatDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmSetPlat">添加</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ELselect from '@/components/myself/el_select'
import { getAllUser, createUser, FrozenUser, giveVip, addOfficialUser, getAllOfficialUser, removeOfficialUser } from '@/api/systemanager/user'
import { encrypt } from '@/libs/md5'
import { getToolPackageKindList } from '@/api/toolmanager/toolVersion.js'
import { getSellerID } from '@/api/ActionSale.js'

export default {
  components: { ELselect },

  data () {
    const checkuserame = (rule, value, callback) => {
      const reStr = /^[a-zA-Z\\]{1}/;
      const realnameReg = /^([a-zA-Z0-9]{1,12})$/;
      if (!reStr.test(value)) {
        callback(new Error("用户名必须以字母开头"));
      } else if (value.length < 6 || value.length > 12) {
        callback(new Error("用户名长度必须在6-12字符间"));
      } else if (!realnameReg.test(value)) {
        callback(new Error("用户名只能包含字母、数字"));
      } else {
        callback();
      }
    };
    return {
      UserList: [],
      queryinfo: {
        pageNum: 1,
        pageSize: 20,
        registTime: '',
        queryname: '',
        type: 0,
        typeNo: '',
        Frozentype: ''
      },
      total: 0,

      Names: [
        {
          label: '全部',
          value: ''
        }, {
          label: '是',
          value: 1
        }, {
          label: '否',
          value: 0
        }],
      Nameskeys: {
        value: 'value',
        label: 'label',
        label2: ''
      },

      types: [
        {
          label: '用户ID',
          value: 0
        }, {
          label: '用户名',
          value: 1
        }, {
          label: '手机号',
          value: 2
        }, {
          label: '邮箱',
          value: 3
        }],

      Frozenkeys: {
        value: 'value',
        label: 'label',
        label2: '',
      },

      Frozens: [
        {
          label: '全部',
          value: ''
        }, {
          label: '已冻结',
          value: 2
        }, {
          label: '未冻结',
          value: 1
        }],

      addDialogVisible: false,
      addForm: {
        username: '',
        password: ''
      },
      addFormRules: {
        username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { validator: checkuserame, trigger: 'blur' },
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, max: 10, message: '长度必须在6-10个字符间', trigger: 'blur' }
        ]
      },

      giveDialogVisible: false,
      addForm1: {
        userID: '',
        VIPdate: '',
        packageKindId: '',
        VIPtype: 0,
        content: '',
        price: '',
        sellerId: ''
      },
      addFormRules1: {
        userID: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        VIPdate: [
          { required: true, message: '内容不能为空', trigger: 'blur' },

        ],
        packageKindId: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        VIPtype: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],

      },
      KindIds: [],
      KindIdskeys: {
        value: 'packageKindId',
        label: 'packageKindName',
        label2: '',
        disabled: 'isFree'
      },

      setDialogVisible: false,
      PlatUserList: [],
      addPlatDialogVisible: false,
      addPlat: {
        userID: ''
      },
      addPlatFormRules: {
        userID: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ]
      },

      sellIds: [],
      sellIdsskeys: {
        value: 'id',
        label: 'name',
        label2: '',
      },
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      let emailV = this.queryinfo.type === 3 ? this.queryinfo.typeNo : ''
      let phoneV = this.queryinfo.type === 2 ? this.queryinfo.typeNo : ''
      let userIdV = this.queryinfo.type === 0 ? this.queryinfo.typeNo : ''
      let userNameV = this.queryinfo.type === 1 ? this.queryinfo.typeNo : ''
      let time1 = this.queryinfo.registTime ? this.queryinfo.registTime[0] : ''
      let time2 = this.queryinfo.registTime ? this.queryinfo.registTime[1] : ''

      let quiese = { email: emailV, phone: phoneV, userId: userIdV, userName: userNameV, createBegin: time1, createEnd: time2, isFrozen: this.queryinfo.Frozentype }
      const res = await getAllUser(this.queryinfo.pageNum, this.queryinfo.pageSize, quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.UserList = res.data.data.list
      this.total = res.data.data.total
    },
    async freeze (para) {
      const res = await FrozenUser(para.UserID, para.FrozenType)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('操作成功')
      this.getList()
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    dataSearch () {
      this.getList()
    },

    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },
    addUser () {
      this.addDialogVisible = true
    },
    confirmAdd () {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          const res = await createUser(encrypt(this.addForm.password), this.addForm.username, window.location.origin + '/')
          if (res.status !== 200) return this.$message.error('操作失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.addDialogVisible = false
          this.getList()
        }
      })
    },
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },

    // vip
    async giveVip () {
      const res = await getToolPackageKindList()
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.KindIds = res.data.data
      this.giveDialogVisible = true
    },
    async getSellerid () {
      const res = await getSellerID()
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.sellIds = res.data.data
    },

    radioGroups (para) {
      if (para === 0) {
        this.addFormRules1.price = []
      } else {
        this.getSellerid()
        this.addFormRules1.price = [{ required: true, message: '内容不能为空', trigger: 'blur' }]
      }
    },
    async confirmGive () {
      this.$refs.addFormRef1.validate(async valid => {
        if (valid) {
          let quiese = { id: this.addForm1.userID, time: this.addForm1.VIPdate, packageKindId: this.addForm1.packageKindId, content: this.addForm1.content, mode: this.addForm1.VIPtype, price: this.addForm1.price, sellerId: this.addForm1.sellerId }
          const res = await giveVip(quiese)
          if (res.status !== 200) return this.$message.error('操作失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.giveDialogVisible = false
          this.getList()
        }
      })
    },
    giveDialogClosed () {
      this.$refs.addFormRef1.resetFields()
    },

    // 官方账户
    async PlatUserFun () {
      const res = await getAllOfficialUser()
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.PlatUserList = res.data.data
    },
    setPlatUser () {
      this.setDialogVisible = true
      this.PlatUserFun()
    },
    addPlatUser () {
      this.addPlatDialogVisible = true
    },
    addPlatDialogClosed () {
      this.$refs.addPlatFormRef.resetFields()
    },
    async confirmSetPlat () {
      this.$refs.addPlatFormRef.validate(async valid => {
        if (valid) {
          const res = await addOfficialUser(this.addPlat.userID)
          if (res.status !== 200) return this.$message.error('操作失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.addPlatDialogVisible = false
          this.PlatUserFun()
        }
      })
    },

    async delets (para) {
      const res = await removeOfficialUser(para.id)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('操作成功')
      this.PlatUserFun()
    },
  }
}
</script>